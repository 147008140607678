@import "../../../../../node_modules/@xyflow/react/dist/style.css";
@import "../../../../variables.scss";

app-grid-visualization {
    display: flex;
    height: 100%;
    padding: $spacer;
    gap: $spacer;
    spinner-animation {
        .content-loading {
            position: unset;
        }
    }
    app-sidebar {
        width: 20%;
        min-width: min-content;
        overflow-y: auto;
        overflow-x: auto;
    }
    .drop-component-container {
        width: 100%;
        border: 1px solid var(--gray-200);
        .drop-container {
            height: 100%;
            .visualization-node {
                color: white;
                padding: $spacer;
                border: 2px dashed white;

                &.status-bad {
                    background-color: var(--red-50);
                }
                &.status-good {
                    background-color: var(--green-50);
                }
                &.status-pending,
                &.status-disabled {
                    background-color: var(--gray-50);
                }
            }
        }
    }
}
