import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Subscription, BehaviorSubject, interval } from "rxjs";
import * as _ from "lodash";

import { SortDirection } from "./../../../directives/sortable.directive";

import { SharedService } from "./../../../services/shared.service";
import { ZixiObject } from "./../../../models/shared";
import { GridsService } from "../../../pages/grids/grids.service";
import { Grid } from "../../../pages/grids/grid";
import { SourcesService } from "src/app/pages/sources/sources.service";

interface State {
    page: number;
    pageSize: number;
    startNum: number;
    endNum: number;
    sortColumn: string;
    sortDirection: SortDirection;
}

@Component({
    selector: "zx-grid",
    templateUrl: "./zx-grid.component.html"
})
export class ZxGridComponent implements OnInit, OnDestroy {
    @Input() id: number;
    @Input() controls?: boolean;
    @Input() rows?: number;
    @Input() fitToScreen?: boolean;

    grid: Grid;
    loadingDetails = true;
    refreshing = false;

    displayAlerts = false;
    displayAudio = false;
    displayTime = false;
    showInfoBtm = false;
    showBottomBar = true;

    firstLoad = true;
    cyclePagination: boolean;
    cyclePaginationInterval: number;

    gridCols: number;
    gridRows: number;
    tileHeight: number;
    gridHeight: number;

    currentObjectIDs: Record<string, number[]>;
    currentObjects: ZixiObject[];

    @ViewChild("gridContent", { static: true }) gridContent: ElementRef;

    private gridSubscription: Subscription;
    private gridRefreshSubscription: Subscription;
    private gridCycleSubscription: Subscription;

    private currentPageObjectsMatrixBS$ = new BehaviorSubject<ZixiObject[][]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);

    private state: State = {
        page: 1,
        pageSize: 20,
        startNum: 0,
        endNum: 0,
        sortColumn: "",
        sortDirection: "asc"
    };

    @HostListener("window:resize", [])
    private onResize() {
        this.prepGridLayout();
    }

    constructor(
        private gs: GridsService,
        private sharedService: SharedService,
        private sourcesService: SourcesService
    ) {}

    ngOnInit() {
        if (this.id) this.grid = this.gs.getCachedGrid(this.id);

        // Loaded Details?
        if (!this.grid || !this.grid.hasFullDetails) this.loadingDetails = true;
        this.gs.getGrid(this.id);

        this.gridSubscription = this.gs.grids.subscribe(grids => {
            this.grid = grids.find((g: Grid) => g.id === this.id);
            if (this.grid && this.grid.hasFullDetails) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    // Cycle Interval
                    if (!this.grid.cycle_pagination_interval) this.cyclePaginationInterval = 10;
                    else this.cyclePaginationInterval = this.grid.cycle_pagination_interval;
                    // Cycle Pagination
                    if (!this.grid.cycle_pagination) this.cyclePagination = false;
                    else {
                        this.cyclePagination = this.grid.cycle_pagination;
                        this.startGridCycle(this.cyclePaginationInterval);
                    }
                    // Settings
                    if (this.grid.fit_screen) this.fitToScreen = true;
                    if (this.grid.display_overlay) this.displayAlerts = true;
                    if (this.grid.display_audio) this.displayAudio = true;
                    if (this.grid.display_time) this.displayTime = true;
                    if (this.grid.show_info_btm) this.showInfoBtm = true;

                    if (this.grid.thumbnails && this.grid.targets) {
                        this.sourcesService.loadSourcesPaginated();
                    }
                }

                this.loadingDetails = false;
                window.setTimeout(() => {
                    this.orderBy();
                    this.prepGridLayout();
                    this.prepGridData();
                }, 0);
            }
        });

        // Start Grid Auto Refresh
        this.startGridRefresh();
    }

    ngOnDestroy() {
        this.gridSubscription.unsubscribe();
        this.stopGridRefresh();
        if (this.gridCycleSubscription) this.stopGridCycle();
    }

    startGridRefresh() {
        this.gridRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopGridRefresh() {
        this.gridRefreshSubscription.unsubscribe();
    }

    startGridCycle(seconds: number) {
        this.gridCycleSubscription = interval(seconds * 1000).subscribe(() => {
            this.page++;
            if (this.page > Math.ceil(this.grid.objects.length / this.pageSize)) this.page = 1;
        });
    }

    stopGridCycle() {
        this.gridCycleSubscription.unsubscribe();
    }

    toggleGridCycle(seconds: number) {
        if (this.cyclePagination) {
            this.stopGridCycle();
            this.cyclePagination = false;
        } else {
            this.cyclePagination = true;
            this.startGridCycle(seconds);
        }
    }

    intervalChange() {
        if (this.gridCycleSubscription) this.stopGridCycle();
        if (this.cyclePagination) this.startGridCycle(this.cyclePaginationInterval);
    }

    prepGridLayout() {
        if (!this.grid) return;

        // calcTileHeight
        this.calcTileHeight();

        // Col
        if (this.grid.cols != null) {
            this.gridCols = this.grid.cols;
        } else {
            if (this.gridContent.nativeElement.offsetWidth >= 1600) this.gridCols = 6;
            else if (
                this.gridContent.nativeElement.offsetWidth >= 1440 &&
                this.gridContent.nativeElement.offsetWidth < 1600
            )
                this.gridCols = 5;
            else if (
                this.gridContent.nativeElement.offsetWidth >= 1200 &&
                this.gridContent.nativeElement.offsetWidth < 1440
            )
                this.gridCols = 4;
            else if (
                this.gridContent.nativeElement.offsetWidth >= 992 &&
                this.gridContent.nativeElement.offsetWidth < 1200
            )
                this.gridCols = 3;
            else if (
                this.gridContent.nativeElement.offsetWidth > 576 &&
                this.gridContent.nativeElement.offsetWidth < 992
            )
                this.gridCols = 2;
            else this.gridCols = 1;
        }

        // Row
        if (this.rows) {
            this.gridRows = this.rows;
            this.pageSize = this.gridRows * this.gridCols;
        } else if (!this.rows && this.grid.rows != null) {
            this.gridRows = this.grid.rows;
            this.pageSize = this.gridRows * this.gridCols;
        } else {
            this.gridHeight = this.gridContent.nativeElement.offsetHeight;
            this.gridRows = Math.floor(Math.max(this.gridHeight / this.tileHeight, 1));
            this.pageSize = this.gridRows * this.gridCols;
        }
    }

    calcTileHeight() {
        let commonHeight = 40;
        let feederExtras = 0;
        let zecExtras = 0;
        const clusterExtras = 0;
        let broadcasterExtras = 0;
        let receiverExtras = 0;
        let sourceExtras = 0;
        const channelExtras = 0;
        const targetExtras = 0;
        const thumbnailHeight = 200;

        if (this.grid.display_detailed_status) commonHeight += 20;
        if (this.grid.display_bitrate) sourceExtras += 20;
        if (this.grid.display_tr101) sourceExtras += 20;
        if (this.grid.display_latency) sourceExtras += 20;
        if (this.grid.display_ip) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_cpu) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_ram) {
            feederExtras += 20;
            broadcasterExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_version) {
            feederExtras += 20;
            broadcasterExtras += 20;
            receiverExtras += 20;
            zecExtras += 20;
        }
        if (this.grid.display_bandwidth) {
            broadcasterExtras += 40;
            zecExtras += 40;
        }

        const feederMaxHeight = !this.grid.feeders ? 0 : commonHeight + feederExtras;
        const clusterMaxHeight = !this.grid.clusters ? 0 : commonHeight + clusterExtras;
        const broadcasterMaxHeight = !this.grid.broadcasters ? 0 : commonHeight + broadcasterExtras;
        const receiverMaxHeight = !this.grid.receivers ? 0 : commonHeight + receiverExtras;
        const sourceMaxHeight = !this.grid.sources ? 0 : commonHeight + sourceExtras;
        const channelMaxHeight = !this.grid.channels ? 0 : commonHeight + channelExtras;
        const targetMaxHeight = !this.grid.targets ? 0 : commonHeight + targetExtras;
        const thumbnailMaxHeight = !this.grid.thumbnails ? 0 : thumbnailHeight;
        const zecMaxHeight = !this.grid.zecs ? 0 : commonHeight + zecExtras;

        this.tileHeight = Math.max(
            feederMaxHeight,
            clusterMaxHeight,
            receiverMaxHeight,
            sourceMaxHeight,
            channelMaxHeight,
            targetMaxHeight,
            broadcasterMaxHeight,
            thumbnailMaxHeight,
            zecMaxHeight
        );
    }

    async refresh() {
        this.refreshing = true;
        await this.gs.refreshGrid(this.id, this.currentObjectIDs, true).toPromise();
        this.refreshing = false;
    }

    orderBy() {
        switch (this.grid.sort_by) {
            case "name":
                this.sortColumn = "name";
                break;
            case "status":
                this.sortColumn = "_frontData.sortableStatus";
                break;
            default:
                this.sortColumn = "_frontData.sortableStatus";
                break;
        }
    }

    get currentPageObjectsMatrix$() {
        return this.currentPageObjectsMatrixBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
        this.refresh();
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    get startNum() {
        return this.state.startNum;
    }
    set startNum(startNum: number) {
        this._set({ startNum });
    }
    get endNum() {
        return this.state.endNum;
    }
    set endNum(endNum: number) {
        this._set({ endNum });
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepGridData();
    }

    private prepGridData() {
        const { sortColumn, sortDirection, pageSize, page } = this.state;

        // sort
        const listofObjs = this.sharedService.sort(this.grid.objects, sortColumn, sortDirection);
        let objects = listofObjs;

        // size
        const total = listofObjs.length;

        // paginate
        this.currentObjects = listofObjs.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        if (this.gridCols) {
            const currentPageObjectsMatrix = this.createGridMatrix(this.currentObjects, this.gridCols);
            this.currentPageObjectsMatrixBS$.next(currentPageObjectsMatrix);
        }

        // get current object IDs
        this.currentObjectIDs = _.reduce(
            this.currentObjects,
            (currentObjectIds, object) => {
                if (!currentObjectIds[object.type]) currentObjectIds[object.type] = [];
                currentObjectIds[object.type].push(object.id);
                return currentObjectIds;
            },
            {}
        );

        // page start & end
        this.state.startNum = page * pageSize - (pageSize - 1);
        this.state.endNum = Math.min(this.state.startNum + pageSize - 1, total);

        this.totalBS$.next(total);
    }

    createGridMatrix(data: ZixiObject[], columns: number): ZixiObject[][] {
        const grid: ZixiObject[][] = [];
        for (let i = 0; i < data.length; i += columns) {
            grid.push(data.slice(i, i + columns));
        }
        return grid;
    }

    // Identify items in dataCollection, it will not destroy and init again
    trackByFunction(index, item) {
        return item ? item.id : undefined;
    }
}
