import { Component, Input, OnInit, inject } from "@angular/core";
import { ZixiObject } from "src/app/models/shared";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Grid } from "../../grid";
import { SharedService } from "src/app/services/shared.service";
import { GridObjectsTypeGuard } from "src/app/utils/type-guards/grid-objects-type-guard";

@Component({
    selector: "app-grid-content",
    templateUrl: "./grid-content.component.html"
})
export class GridContentComponent {
    @Input() grid: Grid;
    @Input() object: ZixiObject;
    @Input() popover?: boolean;
    @Input() overlay?: boolean;
    @Input() displayAlerts?: boolean;
    @Input() displayAudio?: boolean;
    @Input() displayTime?: boolean;
    @Input() infoBtm?: boolean;
    @Input() searchTerm?: string | string[];

    GridObjectsTypeGuard = GridObjectsTypeGuard;

    public targetsService = inject(TargetsService);
    public sharedService = inject(SharedService);
}
