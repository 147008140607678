import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, interval, firstValueFrom, tap, startWith } from "rxjs";

import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { GridGroup } from "../grid-group";
import { Grid } from "../grid";
import { UsersService } from "../../account-management/users/users.service";
import { User } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxIconComponent } from "src/app/components/shared/zx-icon/zx-icon.component";
import { assignIconInputsFactory } from "src/app/components/shared/zx-icon/zx-icon.table-adapter";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-grid-group-list",
    templateUrl: "./grid-group-list.component.html"
})
export class GridGroupListComponent implements OnInit, OnDestroy {
    urls = Constants.urls;
    loading = true;
    refreshing = false;
    user: User;

    private gridGroupsRefreshSubscription: Subscription;
    private router = inject(Router);
    private gs = inject(GridsService);
    private us = inject(UsersService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);

    tableColumnsSchema: TableSchema<GridGroup>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 240,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                gridGroup => gridGroup.name,
                gridGroup => gridGroup.name,
                () => true
            ),
            sortBy: gridGroup => gridGroup.name,
            textValue: gridGroup => gridGroup.name
        },
        {
            header: this.translate.instant("PUBLIC"),
            columnDef: "public",
            width: 80,
            visible: true,
            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory(
                () => "check",
                () => "1x",
                gridGroup => !!gridGroup?.public
            ),
            sortBy: gridGroup => gridGroup?.public
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                // TODO: need to check what is the proper data to use here
                gridGroup => this.getTypeColumnText(gridGroup),
                gridGroup => this.getTypeColumnText(gridGroup),
                () => true
            ),
            sortBy: gridGroup => this.getTypeColumnText(gridGroup),
            textValue: gridGroup => this.getTypeColumnText(gridGroup)
        },
        {
            header: this.translate.instant("GRIDS"),
            columnDef: "grids",
            width: 280,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                gridGroup => this.getGridsColumnText(gridGroup),
                gridGroup => this.getGridsColumnText(gridGroup),
                () => true
            )
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 105,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<GridGroup, Promise<void>>({
                canEditCallBack: gridGroup => this.canEditGridGroup(gridGroup),
                canCloneCallBack: gridGroup => this.canEditGridGroup(gridGroup),
                canDeleteCallBack: gridGroup => this.canEditGridGroup(gridGroup),
                editRef: gridGroup => this.editGridGroup(gridGroup.id),
                cloneRef: gridGroup => this.cloneGridGroup(gridGroup.id),
                deleteRef: gridGroup => this.deleteGridGroup(gridGroup)
            })
        }
    ];

    gridGroups$ = this.gs.gridGroups.pipe(
        tap(() => (this.loading = false)),
        startWith([])
    );
    startGridGroupsRefresh(): void {
        this.gridGroupsRefreshSubscription = interval(60000).subscribe(() => {
            this.gs.refreshGridGroups(false);
        });
    }

    async ngOnInit() {
        // Set Title
        this.titleService.setTitle("GRID_GROUPS", "");
        this.user = await firstValueFrom(this.us.user);

        this.gs.refreshGridGroups(true);

        // Start Auto Refresh
        this.startGridGroupsRefresh();
    }

    ngOnDestroy() {
        this.gridGroupsRefreshSubscription?.unsubscribe();
    }

    canEditGridGroup(gridGroup: GridGroup) {
        return (
            ((this.user.is_admin || this.user.is_objects_manager) && !!gridGroup.public) ||
            this.user.id === gridGroup.user_id
        );
    }

    editGridGroup(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, id, "edit"]);
    }

    cloneGridGroup(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, id, "clone"]);
    }

    async deleteGridGroup(gridGroup: GridGroup): Promise<void> {
        await this.modalService.confirm(
            "DELETE",
            "GRID_GROUP",
            async () => {
                const id = gridGroup.id;
                const result = await this.gs.deleteGridGroup(gridGroup.id);
                if (result) {
                    this.mixpanelService.sendEvent("delete grid-group", { id });
                    this.gs.refreshGridGroups(true);
                } else {
                    return false;
                }
            },
            gridGroup.name
        );
    }

    selectRow(gridGroup: GridGroup): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, gridGroup.id]);
    }

    async refresh(): Promise<void> {
        this.refreshing = true;
        await firstValueFrom(this.gs.refreshGridGroups(true));
        this.refreshing = false;
    }

    getTypeColumnText(gridGroup: GridGroup) {
        switch (gridGroup.mode) {
            case "cycle":
                return this.translate.instant("CYCLE");
            case "stack":
                return this.translate.instant("CUSTOM");
            default:
                return gridGroup.mode;
        }
    }

    getGridsColumnText(gridGroup: GridGroup): string {
        return gridGroup.grids.map((grid: Grid): string => grid.name).join(", ");
    }
}
